<template>
  <main class="usage-policy">
    <b-container>
      <b-row>
        <b-col cols="12">
          <h1 style="color:#060e49;" class="font-bold">{{$t('heading.usage_policy')}}</h1>
        </b-col>
        <b-col cols="12" class="mt-3">
          <p class="font-light" style="font-size:1.3rem;color:#060e49" v-html="usage_policy"></p>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UsagePolicy",
  data() {
    return {
      usage_policy: ""
    };
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"])
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.usage_policy = this.settings("usage_policy");
      },
      immediate: true
    }
  }
};
</script>

<style>
</style>